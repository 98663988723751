import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <>
            <nav class="navbar navbar-expand-lg bg-body-tertiary sticky-top desktop-header">
                <div class="container">
                    <Link class="navbar-brand user-select-none" to="/">
                        Thirteen Thunders.
                    </Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <Link class="nav-link" to='/about-us'>About us</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/insights">Insights</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/services">Services</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/careers">Careers</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/investors">Investors</Link>
                            </li>
                            <li class="nav-item ms-4">
                                <button class="btn-offcanvas" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25px" width="30px">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header">
                    <Link class="navbar-brand-inside user-select-none" to="/">Thirteen Thunders</Link>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <ul className='inside-base-menu'>
                        <li class="nav-item">
                            <Link class="nav-link" to="/news">News</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/candidate-area">Candidate Area</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/faq">FAQ</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/support">Support</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/research">Researchs</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/contact-us">Contact Us</Link>
                        </li>
                    </ul>
                    
                    <div className="social-links">
                    <h3>Follow us at</h3>
                        <ul className="d-flex list-social">
                            
                            <li className='social-btn'><Link to='https://www.linkedin.com/company/thirteen-thunders/' target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                            </svg></Link></li>
                            <li className='social-btn'>
                                <Link to='https://x.com/ThirteenTh89199' target='_blank'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header