import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
const currentYear = new Date().getFullYear();
  return (
    <footer className="text-white py-5">
      <div className="container">
        <div className="row g-lg-5">
          <div className="col-md-7 border-end border-white">
            <Link to='/' className='logobrand user-select-none'>Thirteen Thunders</Link>
            <p className='slowgan'>At Thirteen Thunders, the company aims to be more than just another business entity. It positions itself as a force of nature, a catalyst for change, and a beacon of innovation in a world that seeks progress. The company's mission is to electrify industries, ignite creativity, and redefine what's achievable. With swift action and unwavering determination, it aims to overcome obstacles and create new opportunities. Thirteen Thunders is committed to introducing cutting-edge technology and groundbreaking solutions, with the goal of transforming traditional business paradigms.At Thirteen Thunders, we are more than just another company. We are a force of nature, a catalyst for change, and a beacon of innovation in a world that craves progress.</p>
          </div>
          <div className="col-md-3">
            <h5>Services</h5>
            <ul className="list-unstyled">
              <li>Custom Software Development</li>
              <li>Cloud Computing</li>
              <li>Data Analytics</li>
              <li>AI & Machine Learning</li>
              <li>Cybersecurity</li>
            </ul>
          </div>
          <div className="col-md-2">
            <h5>Quick Navigation</h5>
            <ul className="list-unstyled">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/insights">Insights</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/career">Career</Link></li>
              <li><Link to="/candidate-area">Candidate Area</Link></li>
              <li><Link to="/investors">Investor Relations</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="text-center">
                    <p className='copyright'>&copy; {currentYear} THIRTEEN THUNDERS. All rights reserved. - version 0.11.32.3005</p>
                </div>
            </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
