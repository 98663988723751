import React from 'react'

function Preloader() {
    return (
        <>
            <div class="preloader">
                <div class="loader"></div>
                <p>Just a moment, please.</p>
            </div>

        </>
    )
}

export default Preloader