import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

function ErrorPage(props) {
  useEffect(() => {
    const fetchData = async () => {
      props.setProgress(30); // Loading bar at 30%
      try {
          await new Promise(resolve => setTimeout(resolve, 300));
          props.setProgress(70); // Loading bar at 70%
          await new Promise(resolve => setTimeout(resolve, 100));
          // document.title = props.pageTitle
          props.setProgress(100); // Loading bar at 100%
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  }
  fetchData();
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means this effect runs only once on mount

  return (
    <><div className="d-flex" style={{height : '80vh'}}>
      <div className="container my-auto text-center">
        <h1 className="display-1 text-dark fw-bold">404</h1>
        <h2 className="mb-4">Page Not Found</h2>
        <p className="mb-4">Sorry, the page you are looking for does not exist.</p>
        <Link to="/">
          <button className="btn btn-dark px-5 btn-lg rounded-0">Go Home</button>
        </Link>
      </div>
    </div>
    </>
  )
}

export default ErrorPage