import React, { Suspense, useEffect, useState, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import LoadingBar from 'react-top-loading-bar';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Preloader from './components/Preloader/Preloader';
import ErrorPage from './pages/ErrorPage';
import MobileHeader from './components/Header/MobileHeader';
import JobDetails from './pages/JobDetails';

const Homepage = lazy(() => import('./pages/Homepage'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Insights = lazy(() => import('./pages/Insights'));
const Services = lazy(() => import('./pages/Services'));
const InvestorRelations = lazy(() => import('./pages/InvestorRelations'));
const CandidateArea = lazy(() => import('./pages/CandidateArea'));
const Career = lazy(() => import('./pages/Career'));
const InterviewZone = lazy(() => import('./pages/InterviewZone'));
const Faq = lazy(() => import('./pages/Faq'));
const News = lazy(() => import('./pages/News'));
const Support = lazy(() => import('./pages/Support'));
const Research = lazy(() => import('./pages/Research'));
const Contact = lazy(() => import('./pages/Contact'));

const jobOpenings = [
  { 
    id : 1,
    title: 'Front End Developer',
    description: 'We are seeking a talented Front End Developer with at least 1 year of experience. The ideal candidate will have expertise in HTML, CSS, JavaScript, and React.',
    requirements: [
      'Minimum 1 year of experience in front-end development',
      'Proficient in HTML, CSS, JavaScript, and React',
      'Ability to work collaboratively in a team environment',
    ],
  },
  {
    id : 2,
    title: 'Software Developer (Java)',
    description: 'We are looking for an experienced Software Developer proficient in Java with at least 1 year of experience. The candidate should have strong problem-solving skills and a passion for software development.',
    requirements: [
      'Minimum 1 year of experience in Java development',
      'Strong understanding of object-oriented programming principles',
      'Experience with Spring Framework is a plus',
    ],
  },
  {
    id : 3,
    title: 'Project Manager',
    description: 'We are seeking an experienced Project Manager to lead and oversee various projects. The ideal candidate will have strong leadership skills, excellent communication abilities, and a proven track record of delivering successful projects on time and within budget.',
    requirements: [
      'Minimum 3 years of experience in project management',
      'Excellent leadership and communication skills',
      'Proven track record of delivering projects on time and within budget',
    ],
  },
  {
    id : 4,
    title: 'Marketing Specialist',
    description: 'We are looking for a Marketing Specialist with at least 1 year of experience to develop and implement marketing strategies. The candidate should have strong analytical skills, creativity, and a passion for marketing.',
    requirements: [
      'Minimum 1 year of experience in marketing',
      'Strong analytical and problem-solving skills',
      'Creative thinking and ability to generate innovative marketing ideas',
    ],
  },
  {
    id : 5,
    title: 'Human Resources Coordinator',
    description: 'We are seeking a Human Resources Coordinator with at least 1 year of experience to support various HR functions. The candidate should have strong organizational skills, attention to detail, and knowledge of HR policies and procedures.',
    requirements: [
      'Minimum 1 year of experience in human resources',
      'Strong organizational and multitasking abilities',
      'Knowledge of HR policies and procedures',
    ],
  },
];

const LoadingComponent = () => (
  <div>
    <Preloader />
  </div>
);

const OfflineComponent = () => (
  <div className="offlinemodeStyle">
    <div className="container">
      <h1 className="mb-3 fw-bold">You are currently not connected to the internet.</h1>
      <p>Please ensure that your internet connection is working properly and try again.</p>
    </div>
  </div>
);

const AppRoutes = ({ setProgress }) => (
  <Routes>
    <Route path='/' element={<Homepage setProgress={setProgress} />} />
    <Route path='/about-us' element={<AboutUs setProgress={setProgress} />} />
    <Route path='/insights' element={<Insights setProgress={setProgress} />} />
    <Route path='/services' element={<Services setProgress={setProgress} />} />
    <Route path='/investors' element={<InvestorRelations setProgress={setProgress} />} />
    <Route path='/candidate-area' element={<CandidateArea setProgress={setProgress} />} />
    <Route path='/candidate-interview-zone' element={<InterviewZone setProgress={setProgress} />} />
    <Route path='/careers' element={<Career jobOpenings={jobOpenings} setProgress={setProgress} />} />
    <Route path='/jobOpenings/:id' element={<JobDetails jobOpenings={jobOpenings} setProgress={setProgress} />} />
    <Route path='/faq' element={<Faq setProgress={setProgress} />} />
    <Route path='/news' element={<News setProgress={setProgress} />} />
    <Route path='/support' element={<Support setProgress={setProgress} />} />
    <Route path='/research' element={<Research setProgress={setProgress} />} />
    <Route path='/contact-us' element={<Contact setProgress={setProgress} />} />
    <Route path='*' element={<ErrorPage setProgress={setProgress} />} />
  </Routes>
);

function Navigation() {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const fetchData = () => setLoading(false);
    const timer = setTimeout(fetchData, 2000);

    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!online) return <OfflineComponent />;

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <BrowserRouter>
        <LoadingBar
          color='#000'
          progress={progress}
          height={3}
          shadow={true}
          containerStyle={{ borderRadius: "3px" }}
          onLoaderFinished={() => setProgress(0)}
        />
        <Header />
        <MobileHeader />
        <Suspense fallback={<LoadingComponent />}>
          <AppRoutes setProgress={setProgress} />
        </Suspense>
        <Footer />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default Navigation;
