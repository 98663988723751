import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const JobDetails = ({ jobOpenings }) => {
  const { id } = useParams();
  console.log(jobOpenings)
  console.log(id)
  const job = jobOpenings.find((job) => job.id === parseInt(id));

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
    address: '',
    city: '',
    companyName: '',
    resume: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!job) return;

    const templateParams = {
      ...formData,
      jobTitle: job.title
    };

    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', templateParams, 'YOUR_USER_ID')
      .then((result) => {
        console.log(result.text);
        alert('Your application has been submitted successfully!');
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          message: '',
          address: '',
          city: '',
          companyName: '',
          resume: ''
        });
      }, (error) => {
        console.log(error.text);
        alert('There was an error submitting your application. Please try again later.');
      });
  };

  if (!job) {
    return <div className='mt-inside'>Job not found</div>;
  }

  return (
    <div className='container my-5 mt-inside'>
      <h2>{job.title}</h2>
      <p>{job.description}</p>
      <h4>Requirements:</h4>
      <ul>
        {job.requirements.map((requirement, idx) => (
          <li key={idx}>{requirement}</li>
        ))}
      </ul>
      <h4 className='mt-5 text-center'>Apply for this position</h4>
        <div className="row justify-content-center apply-jobs ">
            <div className="col-lg-6 p-4 bg-light shadow-sm">
            <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="fullName" className="form-label">Full Name</label>
          <input type="text" className="form-control" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} required />
        </div>
        <div className="row">
            <div className="col-lg-6">
            <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
            </div>
            <div className="col-lg-6">
            <div className="mb-3">
          <label htmlFor="phone" className="form-label">Phone</label>
          <input type="tel" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
        </div>
            </div>
        </div>
   
       
        <div className="mb-3">
          <label htmlFor="address" className="form-label">Address</label>
          <input type="text" className="form-control" id="address" name="address" value={formData.address} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="city" className="form-label">City</label>
          <input type="text" className="form-control" id="city" name="city" value={formData.city} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="companyName" className="form-label">Current Company Name</label>
          <input type="text" className="form-control" id="companyName" name="companyName" value={formData.companyName} onChange={handleChange} required />
        </div>
        <button type="submit" className="btn btn-success mt-3">Submit Application</button>
      </form>
            </div>
        </div>
    </div>
  );
};

export default JobDetails;
