import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Responsive.css';
import Navigation from './Navigation';
import { hydrate, render } from "react-dom";
import { hydrateRoot,createRoot } from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Navigation />
  </React.StrictMode>
);

 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrateRoot(<Navigation />, rootElement);
} else {
  createRoot(<Navigation />, rootElement);
}